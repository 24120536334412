import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import { PropTypes } from '@trexity/common/vendor'

const CareerButton = ({ to, title, location }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <div className='well inner half' style={{ marginBottom: 10 }}>
        <div className="row align-center space-between preserve">
          <div className='col'>
            <h4 className='mb-0 mr' style={{ color: '#3C3C3C' }}>{title}</h4>
          </div>
          <div className='col'>
            <div className="row align-center preserve">
              <span className='muted text-right' style={{ color: '#3C3C3C' }}>{location}</span>
              <i className='material-icons' style={{ color: '#4351ee', fontSize: 30 }}>chevron_right</i>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

CareerButton.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string
}

export default function Careers () {
  return (
    <Layout>
      <section>
        <div className='row equal align-center'>
          <div className='col'>
            <div className="inner">
              <h1>What we do, <br className='d-only' />is who we are</h1>
              <p className='lede mb'>Building a business isn’t easy. It takes a great idea, incredible timing, and a cause that is bigger than all of us—that’s Trexity. Our actions define our culture.</p>
              <a href="#open-positions" className='button-link button-link-brand'>Open positions</a>
            </div>
          </div>
          <div className='col'>
            <div className="inner image">
              <img src="/img/careers-hero.jpg" alt="Driver shown with earnings" />
            </div>
          </div>
        </div>
      </section>

      <section id='open-positions'>
        <div className='row justify-center'>
          <div className='col w75'>
            <div className="inner">
              <h2>Open positions</h2>
              <p className='mb'>Help define our culture, process, and products for the future. We encourage all team members to bring up any ideas or issues they have. With a lean team, everyone has an impactful voice, come share yours.</p>
              <CareerButton to='/careers/delivery-support-agent/' title='Delivery Support Agent' location='Remote' />
              <CareerButton to='/careers/sales-representative/' title='Sales Representative' location='Remote' />
              <CareerButton to='/careers/full-stack-software-engineer/' title='Full-stack Software Engineer' location='Remote' />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row equal reverse align-center'>
          <div className='col'>
            <div className="inner image">
              <img src="/img/careers-people.jpg" alt="Selfie of three trexity team members" />
            </div>
          </div>
          <div className='col'>
            <div className="inner">
              <h2>Our people are our strongest asset</h2>
              <p>Our personalities bleed from our daily lives into our work culture and vice versa. Constant learning lives at our core, it fuels our entrepreneurial spirit and empowers us to take ownership.</p>
              <Link to='/about/' className='cta-link'>About us <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
          </div>
        </div>
      </section>

      <section className='well'>
        <div className='row align-center justify-center' style={{ minHeight: 400 }}>
          <div className='col'>
            <div className="inner">
              <h2 className='text-center mb'>Team perks</h2>
              <div className='row equal'>
                <div className='col mr m-mr-0' style={{ marginBottom: 60 }}>
                  <div className="row">
                    <i className='material-icons mb' style={{ fontSize: 40, color: '#4351ee', marginRight: 20 }}>computer</i>
                    <div>
                      <h4>The right tools</h4>
                      <p>We’ll get you what you need to work efficiently, allowing you to contribute creative solutions that you’re proud of.</p>
                    </div>
                  </div>
                </div>
                <div className='col ml m-ml-0' style={{ marginBottom: 60 }}>
                  <div className="row">
                    <i className='material-icons mb' style={{ fontSize: 40, color: '#4351ee', marginRight: 20 }}>verified_user</i>
                    <div>
                      <h4>Benefits plan</h4>
                      <p>Comprehensive medical, dental, and vision plan. Your health is our top priority, without it we have nothing.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row equal'>
                <div className='col mr m-mr-0' style={{ marginBottom: 60 }}>
                  <div className="row">
                    <i className='material-icons mb' style={{ fontSize: 40, color: '#4351ee', marginRight: 20 }}>timelapse</i>
                    <div>
                      <h4>Employee stock options</h4>
                      <p>When we talk about taking ownership of your craft, we mean it. Every employee will participate as owners in the company.</p>
                    </div>
                  </div>
                </div>
                <div className='col ml m-ml-0' style={{ marginBottom: 60 }}>
                  <div className="row">
                    <i className='material-icons mb' style={{ fontSize: 40, color: '#4351ee', marginRight: 20 }}>public</i>
                    <div>
                      <h4>Remote work</h4>
                      <p>We’re digital by design, work from where you can do your best.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col w75 text-center'>
            <div className="inner">
              <h2>We care about what we build</h2>
              <p className='mb'>New features and clean code, or business relationships and partnerships, or support and design—we care about it all, and you should too.</p>
              <Link to='/about/' className='button-link button-link-brand' target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/trexity/jobs'>About us</Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
